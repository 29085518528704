import React from 'react';
import './About.css';

function About() {

    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return (
        <section className='about' id="vantagens">  
            <div className='container'>
                <div className='vantagens'>    
                    <div style={{width: "20%"}}>
                    <img src='vantagem.png' />
                    </div>
                    <div className='vantagem-box'> 
                        <div className='vantagem'>
                            <h3>Transporte Personalizado</h3>
                            <p>Realizamos um transporte personalizado de acordo com a necessidade de nosso cliente, através de uma logística inteligente, garantindo a pontualidade.</p>
                            <img src='transporte-personalizado.png' />
                        </div>

                        <div className='divider'></div>

                        <div className='vantagem'>
                            <h3>Transporte Seguro</h3>
                            <p>Todos os transportes são cobertos pela Sompo Seguros S/A, através dos seguros RCTR-C (Responsabilidade Civil do Transportador Rodoviário - Carga). 
                            Monitoramos nossa frota com a mais alta tecnologia do mercado através do sistema de telemetria veicular.</p>
                            <img src='transporte-seguro.png' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='quem-somos' id="about">
                <div className='container'>
                    <h2>Quem somos?</h2>
                    <p>Nossa frota é composta por caminhões Munck, Pranchas <br/>
                        e Escavadeira.</p>

                    <p>Somos uma empresa de Locação e Transporte de Equipamentos e Cargas Pesadas.</p>

                    <p>Nossos profissionais recebem treinamento e aperfeiçoamento  <br/>
                    constantes.</p>

                    <button  onClick={()=>scrollTo("contact")}>
                        <span>Faça um orçamento</span>
                    </button>
                </div>
            </div>


            <div className='container'>
                <div className='missao-visao-valores'>
                    <div className='card missao'>
                        <h3>Missão</h3>
                        <p>Transportar cargas de <br />
                        maneirasegura, confiável, <br />
                        personalizada através de <br />
                        uma logística inteligente.</p>
                        <img src='missao.png' />
                    </div>

                    <div className='card visao'>
                        <h3>Visão</h3>
                        <p>Garantir o transporte das <br/> cargas com eficiência e <br />segurança.</p>
                        <img src='visao.png' />
                    </div>

                    <div className='card valores'>
                        <h3>Valores</h3>
                        <ul>
                            <li>Agilidade na execução.</li>
                            <li>Valorização dos clientes, <br /> colaboradores e parceiros.</li>
                            <li>Ética e Transparência. </li>
                            <li>Cooperação.</li>
                        </ul>
                        <img src='valores.png' />
                    </div>
                </div>
            </div>

        </section>
    )
}

export default About