import React, {  useState, useRef }  from 'react'
import emailjs from '@emailjs/browser';
import './Footer.css';

function Footer() {

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [mensagem, setMensagem] = useState("");

    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const submit = () => {
        setLoading(true);
        
        if(  !nome || !email || !telefone || !mensagem ){
            setLoading(false);
            setError(true);
            setErrorMessage("Preencha todos os campos!");
        }else{
            send();
        }

    }

    const send = () => {  
        emailjs.send('service_lcspqjb', 'template_uupexjq', {
            nome,
            email,
            telefone,
            mensagem
        }, 'PNXfbwMr9IEBfz1gY')
        .then(function(response) {
            setLoading(false);
            setSent(true);
        }, function(error) {
            setLoading(false);
            alert("Erro ao enviar mensagem.")
        });  
    }


    return (
        <>
        <div className='contato' id="contact">
        <div className='container'>
                <div className='col'>
                    <h2>Fale conosco</h2>
                    <p>Lorem Ipsum is simply dummy <br />
                        text of the printing and types <br />
                        of industries.</p>
                    <div className='divider' />

                    <p className='email'>atendimento@lgttransportes.com.br</p>
                    <p className='phone'><small>(11)</small> 91245-1542</p>
                </div>
                <div className='col'>
                    <form> 
                        { !sent &&
                            <div>
                                <p>Deixe suas informações e entraremos em contato:</p>
                                
                                <div className='line'>
                                    <label>Nome completo:</label>
                                    <input type="text" value={nome} onChange={(e)=>{setNome(e.target.value)}} />
                                </div>

                                <div className='line'>
                                    <label>Endereço de e-mail:</label>
                                    <input type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                                </div>

                                <div className='line'>
                                    <label>Número de telefone:</label>
                                    <input type="text" value={telefone} onChange={(e)=>{setTelefone(e.target.value)}} />
                                </div>

                                <div className='line'>
                                    <label>Sua mensagem:</label>
                                    <textarea rows="5" value={mensagem} onChange={(e)=>{setMensagem(e.target.value)}} />
                                </div>

                                {error &&
                                    <div className='errorMessage'>
                                        <span>{errorMessage}</span>
                                    </div>
                                }

                                <button type='button' onClick={()=>submit()}>
                                    <span>{ loading ? "Enviando...": "Enviar mensagem" }</span>
                                </button>
                            </div>  
                        }
                        { sent &&
                            <div className='sucesso'>
                                <img src='check.png' width="60" />
                                <h3>Mensagem enviada com sucesso!</h3>
                                <p>Sua mensagem foi enviada com sucesso e <br /> em breve entraremos em contato com você.</p>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
        <footer>
            <div className='container'>
                <ul className='social'>
                    <li><div className='item facebook'><img src='facebook.png'/></div></li>
                    <li><div className='item instagram'><img src='instagram.png'/></div></li>
                    <li>
                       <a target="_blank" href='https://wa.me/5511912451542'>
                            <div className='item whatsapp'><img src='whatsapp-small.png'/></div>
                        </a>
                    </li>
                </ul>
                <p>© Todos os direitos reservados | LGT Transportes</p>
            </div>
        </footer>
        <div className='wordvirtua' style={{ backgroundImage: "url(/wordvirtua-bg.png)" }} >
            <div className='container'>
                <img src='wordvirtua.png' />
            </div>
        </div>
        </>
    )
}

export default Footer