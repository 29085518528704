import React from 'react';
import Slider from "react-slick";
import './Services.css';

function Services() {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1, 
        responsive: [
            {
              breakpoint: 1240,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1, 
              }
            }
        ]
    };

  return (
    <section className='services' id="services">  
        <div className='container'>
            <h2>Nossos Serviços</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.  <br />
                The point of using Lorem Ipsum is that it has a more-or-less normal distribution</p> 
            </div>

            <Slider {...settings} className="service-banners"> 
                <div className='item'>
                    <img src='servico.png' /> 
                    <div className='title'>
                        <span>Locação de caminhão Munck</span>
                    </div>
                </div> 
                <div className='item'>
                    <img src='servico.png' /> 
                    <div className='title'>
                        <span>Locação de caminhão Munck</span>
                    </div>
                </div> 
                <div className='item'>
                    <img src='servico.png' /> 
                    <div className='title'>
                        <span>Locação de caminhão Munck</span>
                    </div>
                </div> 
                <div className='item'>
                    <img src='servico.png' /> 
                    <div className='title'>
                        <span>Locação de caminhão Munck</span>
                    </div>
                </div> 
                <div className='item'>
                    <img src='servico.png' /> 
                    <div className='title'>
                        <span>Locação de caminhão Munck</span>
                    </div>
                </div> 
                <div className='item'>
                    <img src='servico.png' /> 
                    <div className='title'>
                        <span>Locação de caminhão Munck</span>
                    </div>
                </div>  
            </Slider> 
    </section>
  )
}

export default Services