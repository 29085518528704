import React from 'react';
import Slider from "react-slick";
import './Banners.css';

function Banners() {

    var settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings} className="banners">
            <>
                <div className='banner' style={{ backgroundImage: "url(/banner-1.png)" }} >
                    <div className='container'>
                        <h1>Locação e Transporte de <br/> Equipamentos e Cargas Pesadas.</h1>
                        <p>Nossa frota é composta por caminhões Munck, Pranchas e Escavadeira.</p>
                    </div>
                </div> 
            </> 
            <>
                <div className='banner' style={{ backgroundImage: "url(/banner-1.png)" }} >
                    <div className='container'>
                        <h1>Locação e Transporte de <br/> Equipamentos e Cargas Pesadas.</h1>
                        <p>Nossa frota é composta por caminhões Munck, Pranchas e Escavadeira.</p>
                    </div>
                </div> 
            </> 
            <>
                <div className='banner' style={{ backgroundImage: "url(/banner-1.png)" }} >
                    <div className='container'>
                        <h1>Locação e Transporte de <br/> Equipamentos e Cargas Pesadas.</h1>
                        <p>Nossa frota é composta por caminhões Munck, Pranchas e Escavadeira.</p>
                    </div>
                </div> 
            </> 
        </Slider>
    )
}

export default Banners