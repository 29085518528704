import React, { useState } from 'react'
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import './Navbar.css';

function Navbar() {

    const [ open, setOpen ] = useState(false);

    const scrollTo = (id, mobile = false) => {
        const element = document.getElementById(id);
        let position = element.getBoundingClientRect();

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            //window.scrollTo(position.left, position.top + window.scrollY - 50);
        }
        if(mobile){
            setOpen(false);
        }
    };

    return (
        <header> 
            <div className='header-container'>
                <div className='left'>
                    <img src='lgd-transportes-logo.png' className='logo' />
                    <ul>
                        <li onClick={()=>scrollTo("services")}><span>Serviços</span></li>
                        <li onClick={()=>scrollTo("vantagens")}><span>Diferenciais</span></li>
                        <li onClick={()=>scrollTo("about")}><span>Sobre</span></li>
                        <li onClick={()=>scrollTo("contact")}><span>Contato</span></li>
                    </ul>
                </div>
                <div className='right'>
                    <span className='phone'><small>(11)</small> 91245-1542</span>
                    <button  onClick={()=>scrollTo("contact")}>
                        <span>Faça um orçamento</span>
                    </button>
                    <a target="_blank" href='https://wa.me/5511912451542'>
                        <button className='whatsapp'>
                            <img src='whatsapp.png' />
                            <span>Fale pelo Whatsapp</span>
                        </button>
                    </a>
                    <button className={ open ? 'mobile-btn open' : 'mobile-btn' } onClick={()=>setOpen(!open)}> 
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span> 
                    </button>
                </div>
            </div>
            <SlideDown className={'my-dropdown-slidedown'}>
                {open &&
                    <div className='mobile-menu'>
                        <ul>
                            <li onClick={()=>scrollTo("services", true)}><span>Serviços</span></li>
                            <li onClick={()=>scrollTo("vantagens", true)}><span>Diferenciais</span></li>
                            <li onClick={()=>scrollTo("about", true)}><span>Sobre</span></li>
                            <li onClick={()=>scrollTo("contact", true)}><span>Contato</span></li>
                        </ul>
                        <button onClick={()=>scrollTo("contact", true)}>
                            <span>Faça um orçamento</span>
                        </button>
                        <a target="_blank" href='https://wa.me/5511912451542'>
                            <button className='whatsapp'>
                                <img src='whatsapp.png' />
                                <span>Fale pelo Whatsapp</span>
                            </button>
                        </a>
                    </div>  
                }
            </SlideDown>
        </header>
  )
}

export default Navbar